// $primary-color: #05445E;
$primary-color: #486c53;
$text-color: #486c53;
$secondary-color: #bbd560;
$button-color: #486c53;
$widget-color: #fff;
$green-color: #69f0ae;
$warning-color: #FFAB40;
$material-warning: #E65100;

$danger-color: #d32f2f;


.co-text-primary {
	color: $primary-color !important;
}

.co-background-primary {
	background: $primary-color !important;
}

.co-background-opacity {
	background: rgba($primary-color, .7) !important;
}

.co-background-secondary {
	background: $secondary-color;
}

.co-background-secondary-opacity {
	background: rgba($secondary-color, .7) !important;
}

.co-text {
	color: $text-color;
}


.co-text-secondary {
	color: $secondary-color !important;
}

.co-green {
	color: $green-color !important;
}

.co-danger {
	color: $danger-color !important;
}

.co-warning {
	color: $warning-color !important;
}

.co-text-opacity {
	color: rgba($text-color, .8) !important;
}

.co-text-secondary-opacity {
	color: rgba($secondary-color, .7) !important;
}


.text-bold {
	font-weight: bold;
}

.text-italic {
	font-style: italic;
}

.multiline-ellipsis {
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	/* start showing ellipsis when 3rd line is reached */
	white-space: pre-wrap;
	/* let the text wrap preserving spaces */
}


.co-background {
	background: rgba(#fff, .5) !important;
	backdrop-filter: blur(5px) !important;
	-webkit-backdrop-filter: blur(5px) !important;

	// filter: blur(20px)
}

.co-background-white {
	background: rgba(#fff, .5) !important;
	backdrop-filter: blur(5px) !important;
	-webkit-backdrop-filter: blur(5px) !important;

	// filter: blur(20px)
}

.co-background-overlay {
	background: rgba(#fff, .3) !important;
	// backdrop-filter: blur(5px) !important;
	// -webkit-backdrop-filter: blur(5px) !important;

	// filter: blur(20px)
}

.co-background-primary-blur {
	background: rgba($primary-color, .8) !important;
	backdrop-filter: blur(5px) !important;
	-webkit-backdrop-filter: blur(5px) !important;

	// filter: blur(20px)
}

.only-mobile {
	display: none !important;
}

.only-laptop {
	display: inherit;
}

.MuiPickersToolbar-root {
	background-color: $primary-color;

	.MuiTypography-root {
		color: $secondary-color;
	}
	
}


.datepicker-text-input {
	cursor: pointer !important;


	fieldset {
		outline: none !important;
		border: none !important;
		box-shadow: none !important;

	}

	input.MuiInputBase-input.MuiOutlinedInput-input {
		cursor: pointer !important;
		text-align: center;
		color: $secondary-color;

		&:hover {
			font-weight: bold;
		}
	}
}

@media (max-width: 768px) {
	.only-mobile {
		display: flex !important;
	}

	.only-laptop {
		display: none !important;
	}
}




