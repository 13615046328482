.legend-container {
	position: absolute;
	bottom: 300px;
	right: 0;
	// z-index: 1;
	height: 200px;
	width: 36px;
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
	padding: 5px;
	// background: $primary-color !important;
	display: flex;
	flex-flow: column;

	.color-wrapper {
		flex: 1 0 auto;

		.of-hidden {
			overflow: hidden;
		}

		.legend-color {
			width: 100%;
			// animation: fadeIn 0.2s linear both;

			&:hover {
				cursor: pointer;
				border: 1px solid gray;
			}
			&:last-child {
				// max-height: 14px;
			}
		}
	}
}

@media (max-width: 1024px) {
	.legend-container {
		bottom: 100px;
	}
}
