.auth-wrapper-container {
  // top: 0;
  // left: 0;
  // border: 1px solid red;
  // background: $primary-color;
  // background: '#fff';
  // background: linear-gradient(180deg, $primary-color -10%, $secondary-color 100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 10;

  overflow-y: auto;
  overflow-x: hidden;
  // align-items: center;
}
