html {
  height: 100%;
}
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb  {
  background: $secondary-color;
  border-radius: 5px;
}

::-webkit-scrollbar-track  {
  background: $primary-color;
}

#root {
  height: 100%;
  width: 100%;
}


.co-app {
  @extend .fade-in;
	// background: #fff;
  // background: linear-gradient(180deg, $primary-color -10%, $secondary-color 100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;

  overflow-y: auto;
  overflow-x: hidden;
}



.MuiDialog-container {
  border-radius: 0 !important;
  
  .MuiPaper-root {
    border-radius: 0 !important;

  }

  .MuiDialogTitle-root {
    background: $primary-color;
    color: $secondary-color;
  }
}

.input-secondary {
  
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid $secondary-color !important;
    color: #fff !important;
  
  }

  label {
        color: $secondary-color !important;
  }

  &:hover {
    .MuiOutlinedInput-notchedOutline {
      border: 2px solid $secondary-color !important;
      color: #fff !important;
  
    }
  }

  
}

.select-secondary {
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid $secondary-color !important;

  }

  .MuiFormLabel-root {
    color: $secondary-color !important;
    font-weight: bold;
    // background: $widget-color !important;
  }

  .MuiSvgIcon-root {
    color: $secondary-color;
  }

  .MuiSelect-select {
    color: #fff;
    font-weight: bold;
  }

  .MuiInputBase-root:hover fieldset.MuiOutlinedInput-notchedOutline {
    border: 2px solid $secondary-color !important;
  }

  
}

.dimension-button.MuiButtonBase-root.MuiButton-root  {
  width: 60px !important;
  min-width: unset;
  display: block !important;

  &.MuiButton-containedSecondary {
    color: #fff;
  }
}

.verification-item-table {
  margin-top: 10px;
  td {
    font-size: 13px;
    white-space: nowrap;
    padding: 2px 0;
    &:first-child {
    
      color: #fff;
      padding-right: 10px
    }

    &:last-child {
      padding-left: 10px;
    }
  }
}

.fade-in {
  animation: fadeIn .2s .0s linear both;
}

.fade-in-2 {
  animation: fadeIn .2s .2s linear both;
}

.fade-in-4 {
  animation: fadeIn .2s .4s linear both;
}

.height-in {
  // animation: heightIn .3s 0s linear both;
}


.height-out {
  // animation: heightOut .3s 0s linear both;
}

.verification-btn-mobile {
  
  display: none !important;
}

.data-widget-mobile {
  display: none !important;
}

.zoom-in-out {
  position: absolute;

  top: 20px;
  left: 345px;

}

@keyframes heightOut {
  from {max-height: 300px}
  to {max-height: 0}
}

@keyframes heightIn {
  from {max-height: 0}
  to {max-height: 800px}
}


@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@media (max-width: 952px) {
  .verification-btn {
    display: none !important;
  }

  .verification-btn-mobile {
    margin-right: '5px' !important;
    display: block !important;
    margin-bottom: '5px' !important
  }

  .verification-widget {
    width: calc(100% - 20px) !important;
  }

  .data-widget-desktop {
    display: none !important;
  }

  .data-widget-mobile {
    display: flex !important;
  }

  .zoom-in-out {
    bottom: 10px;
    left: 10px;
    top: unset;
  }
}